import logo from './logo.svg';
import {AppRouter} from "./AppRouter"
import './App.css';

function App() {
  return (
    <AppRouter />
  );
}

export default App;
