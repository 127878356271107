import React,{useState} from "react";
import { BrowserRouter,Routes, Navigate, Route } from "react-router-dom";
import { FileOutlined, PieChartOutlined, UserOutlined,DesktopOutlined,TeamOutlined } from '@ant-design/icons';
import { Breadcrumb, Layout, Menu } from 'antd';

const { Header, Content, Footer, Sider } = Layout;
function getItem(label, key, icon, children) {
    return {
      key,
      icon,
      children,
      label,
    };
  }
  
  const items = [
    getItem('Option 1', '1', <PieChartOutlined />),
    getItem('Option 2', '2', <DesktopOutlined />),
    getItem('User', 'sub1', <UserOutlined />, [
      getItem('Tom', '3'),
      getItem('Bill', '4'),
      getItem('Alex', '5'),
    ]),
    getItem('Team', 'sub2', <TeamOutlined />, [getItem('Team 1', '6'), getItem('Team 2', '8')]),
    getItem('Files', '9', <FileOutlined />),
  ];
export const AppRouter = () => {
    const [collapsed, setCollapsed] = useState(false);
    return (
        <Layout
      style={{
        minHeight: '100vh',
      }}
    >
      <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
        <div className="logo" />
        <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline" items={items} />
      </Sider>
      <Layout className="site-layout">
        <Header
          className="site-layout-background"
          style={{
            padding: 0,
          }}
        />
        <Content
          style={{
            margin: '0 16px',
          }}
        >
          <Breadcrumb
            style={{
              margin: '16px 0',
            }}
          >
            <Breadcrumb.Item>User</Breadcrumb.Item>
            <Breadcrumb.Item>Bill</Breadcrumb.Item>
          </Breadcrumb>
          <div
            className="site-layout-background"
            style={{
              padding: 24,
              minHeight: 360,
            }}
          >
              <BrowserRouter>
            <Routes>
            <Route  path="/" element={<Navigate to="/home" />} />
            <Route  path="/inventory" element={<Navigate to="/listing" />} />
            <Route path="/home" element={<div>Hello...</div>} />
            </Routes>
            </BrowserRouter>
          </div>
        </Content>
        <Footer
          style={{
            textAlign: 'center',
          }}
        >
          AIMG ©2022 All India Moms Group
        </Footer>
      </Layout>
    </Layout>
      
     
      
    );
  };
  
  export default AppRouter;

